<template>
  <div>
    <div class="mb-6 mt-10 flex items-center justify-between">
      <h1>Gumroad Sales</h1>
      <div v-if="canUserAddASale && firstBatchOfSalesLoaded && gumroadSales.length !== 0">
        <new-button
          v-if="userProfile.integrations.gumroad.currency"
          data-cy="create-sale-button"
          @click="openNewGumroadSaleModal()"
        >
          Create a sale
        </new-button>
        <new-button disabled v-else> Please Wait... </new-button>
      </div>
    </div>

    <new-alert
      :type="userProfile.wasFirstGumroadSaleScheduled ? 'warning' : 'info'"
      class="mb-4"
      v-if="userWeeklySalesLimit === 0"
      data-cy="gumroad-subscription-alert"
    >
      <p class="text-white" v-if="!userProfile.wasFirstGumroadSaleScheduled">
        Automated Gumroad sales are a Subscriber only feature, but your first sale is on us!
      </p>
      <p class="text-white" v-else>
        You are currently on the
        {{ userPlan }}
        plan.<br />
        Automated Gumroad sales are a Subscriber only feature. Click here to
        <router-link
          class="text-main-color-100 underline"
          data-cy="gumroad-upgrade-link"
          to="/billing"
          >grab a Hypefury subscription!</router-link
        >
      </p>
    </new-alert>
    <new-alert type="warning" class="mb-4" v-else-if="shouldShowNextPlanAlert">
      <p>
        You have exceeded your weekly sales limit.<br />
        As a {{ userPlan }} plan user you are limited to {{ userWeeklySalesLimit }} sales per week.
        <a
          href="javascript:;"
          @click="openSalesLimitNextPlanPopUp"
          class="text-main-color-30 underline"
          >Upgrade your plan</a
        >
        to be able to schedule more sales.
      </p>
    </new-alert>

    <new-alert class="mb-4" v-else-if="isUserStandard">
      <p>
        As a {{ getPlanLabel('standard') }} plan user you can schedule Gumroad sales, but Hypefury
        will <i>not</i> post countdowns.<br />
        Countdowns will only work after you upgrade to the {{ getPlanLabel('premium') }} plan.
        <upgrade-to-premium-link>
          <span class="text-main-color-100">Click here to upgrade</span>
        </upgrade-to-premium-link>
        and enjoy many other benefits.
      </p>
    </new-alert>

    <div v-if="canUserAddASale">
      <div class="space-y-4" v-if="firstBatchOfSalesLoaded && gumroadSales.length === 0">
        <ul class="ml-4 list-disc">
          <li>You haven't run any Gumroad sales yet.</li>
          <li>Create a new sale to see it appear here!</li>
        </ul>

        <new-button
          data-cy="create-sale-button"
          v-if="userProfile.integrations.gumroad.currency"
          @click="openNewGumroadSaleModal()"
        >
          Create a sale
        </new-button>
      </div>
    </div>

    <div v-else>
      <div v-if="canUserLinkGumroad">
        <p class="mb-4 text-base">Please connect your Gumroad account from the Settings page.</p>
        <router-link to="/settings">
          <new-button> Go to Settings </new-button>
        </router-link>
      </div>

      <div v-else>
        <p class="mb-4">
          Automating Gumroad sales is possible with our {{ getPlanLabel('premium') }} plan. Upgrade
          your account and benefit from:
        </p>
        <ul class="ml-4 list-disc">
          <li>Automated Gumroad sales</li>
          <li>Daily import of your tweets not posted through Hypefury</li>
          <li>See the retweet and like counters of your tweets</li>
          <li>Ability to automatically boost your popular tweets while they're still hot</li>
        </ul>
        <router-link to="/billing" class="mt-4">
          <new-button> Upgrade to {{ getPlanLabel('premium') }} </new-button>
        </router-link>
      </div>
    </div>

    <div class="space-y-10">
      <card-with-header
        v-for="gumroadSale in gumroadSales"
        :key="gumroadSale.id"
        :title="gumroadSale.gumroadProductName"
        data-cy="gumroad-sale-item"
      >
        <template #actions>
          <new-drop-down placement="left" buttonType="custom" data-cy="gumroad-menu-button">
            <template #button>
              <inline-svg src="/img/icons/moreOptions3dot.svg" class="hover:text-gray-80" />
            </template>
            <template #default>
              <div class="flex flex-col items-center">
                <new-button type="dropdown" @click="duplicateSale(gumroadSale)">
                  <inline-svg src="/img/icons/copy.svg" class="h-5 w-5" />
                  <span>Duplicate Sale</span>
                </new-button>
                <new-button
                  data-cy="delete-sale-button"
                  v-if="isSaleInTheFuture(gumroadSale)"
                  @click="deleteSale(gumroadSale)"
                  type="dropdown"
                >
                  <inline-svg
                    stroke="#f56565"
                    class="h-5 w-5 text-red-500"
                    src="/img/icons/delete.svg"
                  />
                  <span class="text-sm text-red-500">Delete Sale</span>
                </new-button>
                <new-button
                  data-cy="cancel-sale-button"
                  v-if="
                    !gumroadSale.wasCanceled &&
                    !gumroadSale.hasEnded &&
                    isSaleInThePast(gumroadSale)
                  "
                  @click="cancelSale(gumroadSale)"
                  type="dropdown"
                >
                  <inline-svg
                    stroke="#f56565"
                    class="h-5 w-5 text-red-500"
                    src="/img/icons/delete.svg"
                  />
                  <span class="text-sm text-red-500">Cancel Sale</span>
                </new-button>
              </div>
            </template>
          </new-drop-down>
        </template>
        <template #default>
          <div :set="(startingTime = startsAtData(gumroadSale))">
            <p v-if="isSaleInTheFuture(gumroadSale)">
              Starts on {{ startingTime.day }} at {{ startingTime.time }}
            </p>
            <p v-else>
              Started on {{ startingTime.day }} at {{ startingTime.time }}
              <span v-if="gumroadSale.hasEnded">(Ended)</span>
              <span v-else-if="gumroadSale.wasCanceled">(Canceled)</span>
            </p>
          </div>

          <div class="mt-4 flex items-center gap-x-2">
            <inline-svg src="/img/icons/retweet.svg" class="h-5 w-5" />
            <span class="text-xs">You've sold {{ gumroadSale.totalSales }}</span>
            <span v-if="gumroadSale.type !== 'timer'" class="text-xs"
              >of {{ gumroadSale.targetSales }} target sales</span
            >
          </div>
        </template>
      </card-with-header>
    </div>

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />

    <div v-if="isGumroadConnected">
      <new-gumroad-sale-modal
        v-if="isSaleModalVisible"
        :show="isSaleModalVisible"
        @close="closeNewGumroadSalesModal"
        :allGumroadSales="gumroadSales"
        :duplicatedGumroadSale="duplicatedGumroadSale"
      />
    </div>
    <back-to-top-custom />
  </div>
</template>

<script>
  import BackToTopCustom from '@/views/Widgets/BackToTopCustom';
  import { buildGumroadSaleFromFirestore } from '../util/buildGumroadSaleFromFirestore';
  import dao from "@/dao";
  import { mapGetters } from 'vuex';
  import NewGumroadSaleModal from './Modals/NewGumroadSaleModal';
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { store } from '@/store';
  import moment from 'moment';
  import 'moment-timezone';
  import CardWithHeader from '@/components/Cards/CardWithHeader.vue';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import controller from '../controller';
  import UpgradeToNextPlanPopUp from '../components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from './Mixins/UpgradeToNextPlanMixin.vue';

  const fb = require('../firebase');

export default {
  data() {
    return {
      isSaleModalVisible: false,
      firstBatchOfSalesLoaded: false,
      gumroadSales: [],
      duplicatedGumroadSale: null,
      listOfSnapshotsUnsubscriptions: [],
      timeBasedSalesCount: 0,
    };
  },
  components: {
    BackToTopCustom,
    NewGumroadSaleModal,
    CardWithHeader,
    UpgradeToNextPlanPopUp,
  },
  computed: {
    shouldShowNextPlanAlert() {
      const nextPlan = this.getNextPlan('weeklySales');
      // Trial is the last plan, so if the user is on trial getNextPlan won't work as intended
      const isThereANextPlan = this.userProfile.customerStatus !== 'trial' || nextPlan;
      return (
        isThereANextPlan &&
        this.userWeeklySalesLimit !== Infinity &&
        this.currentWeekSalesCount >= this.userWeeklySalesLimit
      );
    },
    currentWeekSalesCount() {
      const startOfCurrentWeek = moment().startOf('week');
      const currentWeekGumroadSales = this.gumroadSales.filter(gumroadSale => {
        const saleStartTime = moment(gumroadSale.startsAt.toDate());
        return saleStartTime.isSameOrAfter(startOfCurrentWeek);
      });
      return this.timeBasedSalesCount + currentWeekGumroadSales.length;
    },
    userWeeklySalesLimit() {
      return this.PLANS[this.userProfile.customerStatus].limits.weeklySales;
    },
    isUserStandard() {
      return this.userProfile.customerStatus === 'standard';
    },
    canUserAddASale() {
      return this.isGumroadConnected;
    },
    canUserLinkGumroad() {
      return !this.isGumroadConnected;
    },
    isGumroadConnected() {
      return this.userProfile.integrations.gumroad &&
        this.userProfile.integrations.gumroad.tokenInfo;
    },
    ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
  },
  methods: {
    cancelSale(gumroadSale) {
      this.swalModal({
        title: 'Delete Sale',
        text: `Are you sure you want to end this sale?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, end this sale',
        preConfirm: () => {
          dao.integrations.cancelGumroadSale(gumroadSale.id).catch((error) => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while ending the sale.`,
              type: 'warning',
            });
            console.error(error);
          });
        },
      });
    },
    closeNewGumroadSalesModal() {
      this.isSaleModalVisible = false;
      this.duplicatedGumroadSale = null;
    },
    duplicateSale(gumroadSale) {
      this.duplicatedGumroadSale = gumroadSale;
      this.openNewGumroadSaleModal();
    },
    deleteSale(gumroadSale) {
      if (this.isSaleInThePast(gumroadSale)) return;
      this.swalModal({
        title: 'Delete Sale',
        text: `Are you sure you want to delete this sale?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete this sale',
        preConfirm: () => {
          dao.integrations.deleteGumroadSale(gumroadSale.id).catch((error) => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while deleting the sale.`,
              type: 'warning',
            });
            console.error(error);
          });
        },
      });
    },
    openNewGumroadSaleModal() {
      this.$eventStore.gumroadSales('Create a sale');
      this.isSaleModalVisible = true;
    },
    // This is pretty much a duplicate of the postingTimeData from models/Post.js
    startsAtData(gumroadSale) {
      const timezone = this.userProfile.timezone;
      const saleStartTime = moment.tz(gumroadSale.startsAt.toDate(), timezone);
      const time = this.formatTimeWithUserLocale(saleStartTime);

      const midnightOfToday = moment.tz(timezone).startOf('day');
      const midnightOfTime = saleStartTime.startOf('day');

      let day = 'today';
      if (midnightOfToday.diff(midnightOfTime, 'day') === 0) {
        day = 'today';
      } else if (midnightOfToday.diff(midnightOfTime, 'day') === -1) {
        day = 'tomorrow';
      } else if (midnightOfToday.diff(midnightOfTime, 'day') === 1) {
        day = 'yesterday';
      } else {
        day = saleStartTime.format('dddd');
        day += ' ' + saleStartTime.format('MMMM Do');
      }

      return { day, time };
    },
    updateGumroadSales() {
      const unsubscribe = fb.gumroadSalesCollection
        .where('userRef', '==', fb.usersCollection.doc(this.userProfile.uid))
        .orderBy('startsAt', 'desc')
        .onSnapshot((doc) => {
          this.gumroadSales = doc.docs.map((d) => buildGumroadSaleFromFirestore(d));
          this.firstBatchOfSalesLoaded = true;
        });
        this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
        store.dispatch('addFirestoreListener', unsubscribe);
    },
    isSaleInTheFuture(gumroadSale){
      const timezone = this.userProfile.timezone;
      const startsAt = moment.tz(gumroadSale.startsAt.toDate(), timezone);
      return startsAt.isAfter(moment.tz(timezone));
    },
    isSaleInThePast(gumroadSale){
      const timezone = this.userProfile.timezone;
      const startsAt = moment.tz(gumroadSale.startsAt.toDate(), timezone);
      return startsAt.isBefore(moment.tz(timezone));
    }
  },
    mixins: [TimeFormatterMixin, SwalModalMixin, CustomerStatusMixin, UpgradeToNextPlanMixin],
  async mounted() {
    this.$eventStore.visitGumroadSalesPage();
    this.updateGumroadSales();

    // We only need to fetch the time based sales count if the user has a limit;
    if (this.userWeeklySalesLimit >= 0 && this.userWeeklySalesLimit !== Infinity) {
      this.timeBasedSalesCount = (await controller.getCurrentWeekTimeBasedSalesCount(
        this.currentUser,
        this.userProfile.uid
      )).data;
    }
  },
  beforeDestroy() {
    this.listOfSnapshotsUnsubscriptions.forEach((unsubscribe) => {
      if (!unsubscribe) return;
      unsubscribe();
    });
  },
};
</script>

<style>
  @supports(padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom)!important;
    }
  }
</style>
