import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import moment from 'moment';
import 'moment-timezone';

const fb = require('../firebase');

class GumroadSale {
  amountOff;
  gumroadProductId;
  gumroadProductName;
  hasEnded;
  hasExpired;
  id;
  isSuccessful;
  offerCode;
  saleDuration;
  startsAt;
  status;
  targetSales;
  totalSales;
  user;
  wasCanceled;
  replyDelay;
  createdAt;
  enableQuoteTweets;
  customWording;
  type;
  repliesDelays;
  autoDeleteTweets;
  tweetsDeleted;
  isFirstGumroadSale;

  constructor(
    amountOff,
    gumroadProductId,
    gumroadProductName,
    hasEnded,
    hasExpired,
    id,
    isSuccessful,
    offerCode,
    saleDuration,
    startsAt,
    status,
    targetSales,
    totalSales,
    user,
    wasCanceled,
    replyDelay,
    enableQuoteTweets,
    customWording,
    createdAt,
    type,
    repliesDelays,
    autoDeleteTweets,
    tweetsDeleted,
    isFirstGumroadSale
  ) {
    this.amountOff = amountOff;
    this.gumroadProductId = gumroadProductId;
    this.gumroadProductName = gumroadProductName;
    this.hasEnded = hasEnded;
    this.hasExpired = hasExpired;
    this.id = id;
    this.isSuccessful = isSuccessful;
    this.offerCode = offerCode;
    this.saleDuration = saleDuration;
    this.startsAt = startsAt;
    this.status = status;
    this.targetSales = targetSales;
    this.totalSales = totalSales;
    this.user = user;
    this.wasCanceled = wasCanceled;
    this.replyDelay = replyDelay;
    this.createdAt = createdAt;
    this.enableQuoteTweets = enableQuoteTweets;
    this.customWording = customWording;
    this.type = type;
    this.repliesDelays = repliesDelays;
    this.autoDeleteTweets = autoDeleteTweets;
    this.tweetsDeleted = tweetsDeleted;
    this.isFirstGumroadSale = isFirstGumroadSale;
  }

  static DEFAULT_GUMROAD_WORDING = {
    nCopiesLeftBefore: '',
    nCopiesLeftAfter: 'copies left!',
    oneCopyLeft: '1 copy left!',
    saleOver: 'Sale over!',
    twoMinutesLeft: '2 minutes left!',
    fiveMinutesLeft: '5 minutes left!',
    fifteenMinutesLeft: '15 minutes left!',
    thirtyMinutesLeft: '30 minutes left!',
    oneHourLeft: '1 hour left!',
  }

  static newGumroadSale(
    amountOff,
    gumroadProductId,
    gumroadProductName,
    offerCode,
    saleDuration,
    startsAt,
    status,
    targetSales,
    user,
    replyDelay,
    enableQuoteTweets,
    customWording,
    type,
    repliesDelays,
    autoDeleteTweets,
    isFirstGumroadSale,
  ) {
    return new GumroadSale(
      amountOff,
      gumroadProductId,
      gumroadProductName,
      false,
      false,
      null,
      false,
      offerCode,
      saleDuration,
      startsAt,
      status,
      targetSales,
      null,
      user,
      false,
      replyDelay,
      enableQuoteTweets,
      customWording,
      null,
      type,
      repliesDelays,
      autoDeleteTweets,
      false,
      isFirstGumroadSale,
    );
  }

  saveToFirestore(timezone) {
    return fb.gumroadSalesCollection.add({
      amountOff: this.amountOff * 100,
      endTime: moment.tz(this.startsAt, timezone).add(this.saleDuration, 'hour').toDate(),
      gumroadProductId: this.gumroadProductId,
      gumroadProductName: this.gumroadProductName,
      hasEnded: false,
      hasExpired: false,
      isSuccessful: false,
      offerCodeName: this.offerCode,
      saleDuration: this.saleDuration,
      startsAt: this._timeForFirestore(timezone),
      status: this.status,
      targetSales: this.targetSales,
      userRef: this.user,
      wasCanceled: false,
      replyDelay: this.replyDelay,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      totalSales: 0,
      enableQuoteTweets: this.enableQuoteTweets,
      customWording: this.customWording,
      type: this.type,
      repliesDelays: this.repliesDelays,
      autoDeleteTweets: this.autoDeleteTweets,
      tweetsDeleted: this.tweetsDeleted,
      isFirstGumroadSale: this.isFirstGumroadSale,
    });
  }

  static buildFromFirestore(doc) {
    return new GumroadSale(
      doc.data().amountOff,
      doc.data().gumroadProductId,
      doc.data().gumroadProductName,
      doc.data().hasEnded,
      doc.data().hasExpired,
      doc.id,
      doc.data().isSuccessful,
      doc.data().offerCodeName,
      doc.data().saleDuration,
      doc.data().startsAt,
      doc.data().status,
      doc.data().targetSales,
      doc.data().totalSales,
      doc.data().userRef,
      doc.data().wasCanceled,
      doc.data().replyDelay,
      doc.data().enableQuoteTweets,
      doc.data().customWording,
      doc.data().createdAt,
      doc.data().type,
      doc.data().repliesDelays,
      doc.data().autoDeleteTweets,
      doc.data().tweetsDeleted,
      doc.data().isFirstGumroadSale,
    );
  }

  _timeForFirestore(timezone) {
    return moment.tz(this.startsAt, timezone).toDate();
  }
}

export { GumroadSale };